<template>
	<div class="ordermange" style="font-size: 14px;">
		<div class="filter-container" style="display: flex;align-items: center;margin-bottom: 10px;">
			<div>
				<div class="filter-item">
					<label class="label">订单编号:</label>
					<el-input v-model="orderNoKey" placeholder="请输入订单编号" @keyup.enter.native="goodsFilter" style="width: 250px;"
					clearable></el-input>
				</div>
				<div class="filter-item">
					<label class="label">商品名称:</label>
					<el-input v-model="nameKey" placeholder="请输入商品名称" @keyup.enter.native="goodsFilter" style="width: 250px;"
					clearable></el-input>
				</div>
				<div class="filter-item">
					<label class="label">手机号:</label>
					<el-input v-model="phoneKey" placeholder="请输入手机号" @keyup.enter.native="goodsFilter" style="width: 250px;"
					clearable></el-input>
				</div>
                <div class="filter-item">
                    <label class="label">支付方式:</label>
                    <el-select v-model="payType" style="width: 200px;margin-right: 10px;">
                        <el-option v-for="item in payTypeList" :key="item.value" :label="item.lable" :value="item.value"></el-option>
                    </el-select>
                </div>
			</div>
		</div>
		<div class="filter-container">
			<div class="filter-item">
				<label class="label">订单类型:</label>
				<el-select v-model="orderType" style="width: 200px;margin-right: 10px;">
					<el-option v-for="item in orderTypeList" :key="item.value" :label="item.lable" :value="item.value"></el-option>
				</el-select>
			</div>
            <div class="filter-item">
				<label class="label">商品供应商:</label>
				<el-select v-model="supplierId" placeholder="请选择商品供应商" @change="changeSupplierId()" filterable clearable>
                    <el-option v-for="item in productList" :key="item.id" :label="item.supplierName" :value="item.id"></el-option>
                </el-select>
			</div>
            <div class="filter-item">
				<label class="label">品牌商:</label>
				<el-select v-model="brandId" placeholder="请选择品牌商"  @change="changeBrandId()" filterable clearable>
                    <el-option v-for="item in showBrandList" :key="item.id" :label="item.brandName" :value="item.id"></el-option>
                </el-select>
			</div>

			<div class="filter-item">
				<label class="label">下单时间:</label>
				<el-date-picker
					v-model="orderTimes"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="～"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="yyyy-MM-dd HH:mm:ss"
				></el-date-picker>
			</div>
		</div>
		<div class="filter-container">
			<div class="filter-item">
				<label class="label">付款时间:</label>
				<el-date-picker
					v-model="payTimes"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="～"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="yyyy-MM-dd HH:mm:ss"
				></el-date-picker>
			</div>
			<div class="filter-item">
				<label class="label">发货时间:</label>
				<el-date-picker
					v-model="sendTimes"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="～"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="yyyy-MM-dd HH:mm:ss"
				></el-date-picker>
			</div>
			<div class="filter-item">
				<label class="label">完成时间:</label>
				<el-date-picker
					v-model="finishTimes"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="～"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="yyyy-MM-dd HH:mm:ss"
				></el-date-picker>
			</div>
		</div>

		<div class="filter-container">
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="goodsFilter">查询</el-button>
			</div>
			<div class="filter-item">
				<buttonPermissions :datas="'downloadOrder'">
					<el-button style="width:90px;" v-loading="exportLoading" @click="orderExport">导出</el-button>
				</buttonPermissions>
			</div>
		</div>
		<div style="margin-left: 20px;margin-bottom: 15px;font-size: 18px;" >订单列表( 订单数量：{{totalOrder||0}}单，订单金额：{{totalMoney||0}}元
			)</div>
		<div style="display: flex;align-items: center;">
			<div v-for="(item,index) in orderStateList" :key="index" @click="handleClick(index)">
				<div v-if="item.isCheck" style="align-items: center;padding: 7px 0;background-color: #409EFF;width: 120px;text-align: center;color: #fff;margin-left: 20px;">
					<div>{{item.title}}</div>
					<div style="font-size: 12px;margin-top: 5px;">({{item.count}})</div>
				</div>
				<div v-else style="align-items: center;padding: 7px 0;width: 120px;text-align: center;border: 1px solid #ddd;margin-left: 20px;">
					<div>{{item.title}}</div>
					<div style="color: #f04844;font-size: 12px;margin-top: 5px;">({{item.count}})</div>
				</div>
			</div>
		</div>
		<!-- 表格 -->
		<div class="table-container" style="margin-top:20px">
			<el-table :data="groupData" :default-expand-all="true" style="width: 1648px;" v-loading="loading" row-class-name="default-row"
			border>
				<el-table-column type="expand">
					<template slot-scope="scope">
						<div style="width: 1648px;display: flex;align-items: center;">
							<div v-if="scope.row.childOrders.length">
								<div style="margin: 10px 0;border: 1px solid #ddd;padding: 10px;width: 950px;display: flex;align-items: center;"
								v-for="(item,index) in scope.row.childOrders" :key="index">
									<div>
										<div style="margin-bottom: 10px;" v-if="item.packageName">{{item.packageName}}</div>
										<div style="display: flex;align-items: center;margin-bottom: 10px;" v-for="(items,indexs) in item.orderDetailVOs"
										:key="indexs">
											<div style="display: flex;align-items: center;width: 300px;">
												<img :src="items.skuImgUrl+'?x-oss-process=image/resize,m_fixed,h_80,w_80'" style="width: 50px;height: 50px;border: 1px solid #ddd;" />
												<div style="height: 45px;margin-left: 10px;display: flex;flex-direction: column;justify-content: space-between;">
													<div style="width: 180px;">{{items.goodsName}}</div>
													<div style="width: 180px;">{{items.specValues}}</div>
                                                    <el-tag v-if='items.ledgerAccountType == 1' effect="dark" size='mini' style="width:56px;">A类商品</el-tag>
                                                    <el-tag v-if='items.ledgerAccountType == 2' type="success" effect="dark" size='mini' style="width:56px;">B类商品</el-tag>
                                                    <el-tag v-if='items.ledgerAccountType == 3' type="warning" effect="dark" size='mini' style="width:56px;">C类商品</el-tag>
                                                </div>
											</div>
											<div style="width: 250px;">￥{{items.unitPrice}}</div>
											<div style="width: 250px;">x{{items.quantity}}</div>
											<!-- <div style="width: 130px;text-align: center;">
												<div style="background-color: #f2f2f2;padding: 8px 0;text-align: center;">{{items.orderStatusValue}}</div>
											</div> -->
										</div>
									</div>
									<div style="width: 130px;text-align: center;">
										<div style="background-color: #f2f2f2;padding: 8px 0;text-align: center;">{{item.orderStatusValue}}</div>
									</div>
									<!-- <div
										v-if="items.orderStatus>=3"
										style="margin-top: 5px;color: #007AFF;"
										@click="lookOrderDetails(scope.row)"
									>查看物流</div>-->
								</div>
							</div>
							<div v-else style="margin: 10px 0;padding: 10px;width: 950px;height: 100px;"></div>
							<div style="width: 200px;margin-left: 110px;">
								<div style="color: #007AFF;">{{scope.row.phoneNumber}}</div>
								<div style="padding: 5px 0;">{{scope.row.receiveName}}</div>
								<div style="padding: 5px 0;">{{scope.row.receivePhone}}</div>
								<div style="color: #f00;" v-if="scope.row.orderType==4">总部平台代下单</div>
							</div>
							<div style="width: 200px;">
								<div>商品总额：￥{{scope.row.orginalAmount || 0}}</div>
								<div style="padding-top: 5px;">优惠：￥{{scope.row.discountAmount || 0}}</div>
								<div style="padding-top: 5px;">运费：￥{{scope.row.freightAmount || 0}}</div>
								<div style="padding-top: 5px;">实收金额：￥{{scope.row.paymentAmount || 0}}</div>
							</div>
							<div style="width: 150px;">
								<!-- <div @click="orderMark(scope.row)">备注：{{scope.row.remark ? scope.row.remark : "无"}}</div> -->
								<buttonPermissions :datas="'orderMangeRemarks'">
									<el-button type="text" class="pointers" @click="orderMark(scope.row)">备注</el-button>
								</buttonPermissions>
								<buttonPermissions :datas="'orderMangeSend'">
									<el-button type="text" class="pointers" v-if="scope.row.childOrders && scope.row.childOrders.length && scope.row.childOrders[0].orderStatus == 2"
									@click="deliverGoods(scope.row,1)" style="margin-left: 10px;">发货</el-button>
								</buttonPermissions>
								<buttonPermissions :datas="'orderMangeExpress'">
									<el-button type="text" class="pointers" v-if="scope.row.childOrders && scope.row.childOrders.length && scope.row.childOrders[0].orderStatus == 3"
									@click="deliverGoods(scope.row,2)" style="margin-left: 10px;">修改物流</el-button>
								</buttonPermissions>
								<buttonPermissions :datas="'editAddress'" v-if="scope.row.childOrders && scope.row.childOrders.length && scope.row.childOrders[0].orderStatus == 2">
									<el-button type="text" @click="addressDialogShow(scope.row)" class="pointers">修改收货地址</el-button>
								</buttonPermissions>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="商品" width="300">
					<template slot-scope="scope">
						<div>订单号：{{scope.row.orderNO}}</div>
					</template>
				</el-table-column>
				<el-table-column label="单价" width="250">
					<template slot-scope="scope">
						<div>下单时间：{{scope.row.createTime}}</div>
					</template>
				</el-table-column>
				<el-table-column label="数量" width="250">
					<template>
						<div>配送方式：快递发货</div>
					</template>
				</el-table-column>
				<el-table-column label="订单状态" width="250">
					<template slot-scope="scope">
						<div>
							订单类型：
							<span style="background: #f86638;color: #fff;padding: 3px 10px;">
                                <span v-if="scope.row.orderType == 8">{{scope.row.giftType == 1 ? 'C级' : scope.row.giftType == 2 ? 'B级' : 'B级自选' }}</span>
                                {{ scope.row.orderType | orderTypeFilter }}
                            </span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="买家/收货人" width="200"></el-table-column>
				<el-table-column label="实收" width="200"></el-table-column>
				<el-table-column label="操作" width="150">
					<template slot-scope="scope">
						<buttonPermissions :datas="'orderMangeDetial'">
							<el-button type="text" @click="lookOrderDetails(scope.row)" class="pointers">订单详情</el-button>
						</buttonPermissions>
					</template>
				</el-table-column>
			</el-table>

			<div style="text-align: right;padding-bottom: 10px;">
				<el-pagination v-if="Total" style="margin:20px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				:total="Total"></el-pagination>
			</div>
		</div>

		<!-- 订单发货的弹框 -->
		<!-- 快递 -->
		<el-dialog title="发货信息" :visible.sync="SendDialogVisible" width="850px" :before-close="closeDeliver">
			<el-form :model="sendForm" ref="sendForm" :rules="rules">
				<el-form-item label="物流公司 :" label-width="150px" prop="ExpressId">
					<el-select v-model="sendForm.ExpressId" placeholder="请选择物流公司" style="width:300px;">
						<el-option v-for="(i,index) in  ExpressList" :key="index" :label="i.ExpressName" :value="i.Id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="物流单号 :" label-width="150px" prop="ExpressNo">
					<input v-model="sendForm.ExpressNo" style="padding:0 10px;width:300px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;" />
				</el-form-item>
			</el-form>
			<div style="text-align: center;">
				<el-button @click="closeDeliver" style="width:120px">取 消</el-button>
				<el-button type="primary" @click="submitSends('sendForm')" :loading="loading" style="width:120px">确认</el-button>
			</div>
		</el-dialog>

		<!-- 查看物流 -->
		<el-dialog :visible.sync="logistdialogVisible" title="物流详情">
			<el-tabs type="border-card">
				<div class="express-info">
					<div class="info-item">
						<label class="label">物流公司</label>
						<span>{{expressInfo.ExpressValue}}</span>
					</div>
					<div class="info-item">
						<label class="label">物流单号</label>
						<span>{{expressInfo.ExpressNo}}</span>
					</div>
					<div class="info-item">
						<label class="label">物流跟踪</label>
						<div class="item-detail" v-if="expressInfo.ExpressTraceList  && expressInfo.ExpressTraceList.length">
							<div class="m-step" v-for="(j,index) in expressInfo.ExpressTraceList " :key="index">
								<div v-if="j && j.AcceptTime">
									<div class="head">
										<div class="line"></div>
										<div class="icon"></div>
									</div>
									<div class="main">
										<div class="card">
											<div class="title">{{j.AcceptTime}}</div>
											<div class="content">{{j.AcceptStation}}</div>
										</div>
									</div>
								</div>
							</div>
							<div v-if="!j || !j.AcceptTime" style="margin-top:6px;">暂无信息</div>
						</div>
						<div v-else style="margin-top:23px">暂无跟踪到物流信息</div>
					</div>
				</div>
			</el-tabs>
			<!-- 	<div style="text-align: center;margin-top: 10px;">
				<el-button type="primary" @click="logistdialogVisible=false">关闭</el-button>
			</div>-->
		</el-dialog>

		<!-- 订单备注弹框 -->
		<el-dialog title="订单备注" :visible.sync="ordermarkDialogVisible" width="650px" @close="remarkClose">
			<el-form :model="orderForm" ref="orderForm" :rules="rules">
				<el-form-item label="订单备注 :" label-width="120px" prop="orderMark">
					<el-input v-model="orderForm.orderMark" maxlength="200" type="textarea" style="width:300px;" clearable></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="ordermarkDialogVisible=false">关 闭</el-button>
				<el-button type="primary" @click="sureOrdermark('orderForm')" :loading="loading">确 认</el-button>
			</span>
		</el-dialog>

		<!-- 发货弹窗 -->
		<el-dialog title="订单发货" :visible.sync="deliverGoodsDialog" width="650px" @close="remarkClose">
			<el-table ref="multipleTable" :data="deliverGoodsData.list" tooltip-effect="dark" @selection-change="changeFun">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column label="商品信息" width="220">
					<template slot-scope="scope">
						<div style="display:flex;align-items: center;">
							<img :src="scope.row.skuImgUrl+'?x-oss-process=image/resize,m_fixed,h_80,w_80'" style="width:60px;height:60px;" />
							<div style="margin-left:10px;">
								<p>{{ scope.row.goodsName }}</p>
								<p>{{ scope.row.goodsCode }}</p>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="quantity" label="数量" width="80"></el-table-column>
				<el-table-column prop="address" label="物流单号">
					<template slot-scope="scope">
						<div>{{ scope.row.logisticsCompany }}</div>
						<div>{{ scope.row.expressNo }}</div>
					</template>
				</el-table-column>
				<el-table-column prop="address" label="状态">
					<template slot-scope="scope">{{scope.row.isDeliver?'已发货':'待发货'}}</template>
				</el-table-column>
			</el-table>
			<el-form :model="deliverGoodsForm" ref="deliverGoodsForm" label-width="120px" style="margin-top:30px;">
				<el-form-item label="物流公司:" prop="logisticsCompany" :rules="[{required:true,message:'请选择物流公司',trigger: 'blur'}]">
					<el-select v-model="deliverGoodsForm.logisticsCompany">
						<el-option v-for="(item,index) in companyList" :key="index" :label="item.logisticsCompanyName" :value="item.logisticsCompanyName"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="物流单号:" prop="expressNo" :rules="[{required:true,message:'物流单号不能为空',trigger: 'blur'}]">
					<el-input v-model="deliverGoodsForm.expressNo" clearable style="width:200px;"></el-input>
				</el-form-item>
				<el-form-item label="收货信息:">
					<div>
						{{deliverGoodsData.receiveProvince}}
						{{deliverGoodsData.receiveCity}}
						{{deliverGoodsData.receiveArea}}
						{{deliverGoodsData.receiveDetailAddress}}
					</div>
					<div>
						{{deliverGoodsData.receiveName}}
						{{deliverGoodsData.receivePhone}}
					</div>
				</el-form-item>
				<el-form-item label="实名信息:" v-if="deliverGoodsData.hasOverseas">
					<div >姓名：{{deliverGoodsData.realName}}</div>
					<div >身份证号：{{deliverGoodsData.idCardNo}}</div>
					<div style="display: flex;align-items: center;">
						<img :src="deliverGoodsData.frontImgUrl" alt="" style="width: 200px;height: 112px;">
						<img :src="deliverGoodsData.reverseImgUrl" alt="" style="width: 200px;height: 112px;margin-left: 20px;">
					</div>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="deliverGoodsDialog=false">关 闭</el-button>
				<el-button type="primary" @click="sureDeliverGoods()" :loading="loading">确 认</el-button>
			</span>
		</el-dialog>

		<!-- 修改收货地址 -->
		<el-dialog title="修改收货地址" :visible.sync="editAddressDialog" width="650px" @close="addressClose">
			<el-form :model="editAddressForm" ref="editAddressForm" label-width="120px" style="margin-top:30px;">
				<el-form-item label="收货人 :" label-width="150px" prop="receiveName" :rules="[{required:true,message:'收货人不能为空',trigger: 'blur'}]">
					<el-input v-model="editAddressForm.receiveName" placeholder="收货人" clearable style="width:250px" maxlength="20"></el-input>
				</el-form-item>
				<el-form-item label="收货人手机 :" label-width="150px" prop="receivePhone" :rules="[{required:true,message:'收货人手机不能为空',trigger: 'blur'}]">
					<el-input v-model="editAddressForm.receivePhone" placeholder="收货人手机" clearable style="width:250px" maxlength="11"></el-input>
				</el-form-item>
				<el-form-item label="收货地址" prop="addressArr" label-width="150px" :rules="[{required:true,message:'请选择省市区',trigger: 'blur'}]">
					<el-cascader separator="-" style="width: 250px;" :props="props" :options="dataList" @change="handleAddressChange"
					v-model="editAddressForm.addressArr" />
				</el-form-item>
				<el-form-item label="详细地址 :" label-width="150px" prop="receiveDetailAddress" :rules="[{required:true,message:'详细地址不能为空',trigger: 'blur'}]">
					<el-input v-model="editAddressForm.receiveDetailAddress" placeholder="详细地址" clearable style="width:250px" maxlength="200" type="textarea"
                    :rows="2"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="editAddressDialog=false">关 闭</el-button>
				<el-button type="primary" @click="sureEditAddress()">确 认</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		orderIndex,
		orderRemark,
		orderDeliver,
		deliverDetial,
		orderStatistics,
		updateAddress,
		orderExport,
        supplierGetList,
        findBrandPage
	} from '@/api/goods'
	import {
		companyList
	} from '@/api/setting'
	import config from '@/config/index'
	import buttonPermissions from '@/components/buttonPermissions';
    import util from '@/utils/util.js'
	// import axios from 'axios'

	export default {
        name: 'orderMange',
		components: {
			buttonPermissions
		},
		data() {
			var checkPhone = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('手机号不能为空'));
				} else {
					const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
					console.log(reg.test(value));
					if (reg.test(value)) {
						callback();
					} else {
						return callback(new Error('请输入正确的手机号'));
					}
				}
			};
			return {
				exportLoading: false,
				phoneKey: '',
				orderNoKey: '',
				expressNoKey: '',
				nameKey: '',
				orderStartTime: '',
				orderEndTime: '',
				payStartTime: '',
				payEndTime: '',
				expressStartTime: '',
				expressEndTime: '',
				successStartTime: '',
				successEndTime: '',
				payType: 0,
				payTypeList: [{
						lable: '全部',
						value: 0
					},
					{
						lable: '微信',
						value: 1
					},
					{
						lable: '通联支付',
						value: 2,
					},
					{
						lable: '通商云',
						value: 3,
					}
				],
				orderType: 0,
				orderTypeList: [{
						lable: '全部',
						value: 0
					},
					{
						lable: '商品订单',
						value: 1
					},
                    {
						lable: '卡券订单',
						value: 6,
                    },
                    {
						lable: '礼包订单',
						value: 8,
                    },{
						lable: '奖品订单',
						value: 9,
                    },{
						lable: '拼团订单',
						value: 10,
                    },{
						lable: '24H模式订单',
						value: 11,
                    },{
						lable: '换货订单',
						value: 12,
					}
				],
				sendType: null,
				sendTypeList: [],
				RefundInfo: '',
				RefundInfoList: [{
						id: 1,
						value: '退款中'
					},
					{
						id: 2,
						value: '退款结束'
					}
				],
				groupData: [],
				loading: false,
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				SendDialogVisible: false,
				sendForm: {
					ExpressId: '',
					ExpressNo: ''
				},
				ExpressList: [], //所有的物流公司
				rules: {
					ExpressId: [{
						required: true,
						message: '请选择物流公司',
						trigger: 'change'
					}],
					ExpressNo: [{
						required: true,
						message: '请输入物流单号',
						trigger: 'blur'
					}],
					closeReason: [{
						required: true,
						message: '请选择订单关闭理由',
						trigger: 'change'
					}],
					orderMark: [{
							required: true,
							message: '请填写订单备注',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 200,
							message: '长度在 1 到 200 个字符',
							trigger: 'blur'
						}
					],
					Name: [{
							required: true,
							message: '请输入收货人姓名',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 15,
							message: '长度在 1 到 15 个字符',
							trigger: 'blur'
						}
					],
					phone: [{
						required: true,
						validator: checkPhone,
						trigger: 'blur'
					}],
					AddressArr: [{
						required: true,
						message: '请选择收货地址',
						trigger: 'change'
					}],
					Address: [{
							required: true,
							message: '请输入详细地址',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 100,
							message: '长度在 1 到 100 个字符',
							trigger: 'blur'
						}
					],
				},
				closeReason: '',
				closeReasonList: ['无法联系上买家', '买家误怕或重拍了', '买家无诚意完成交易'],
				logistdialogVisible: false,
				expressInfo: {},
				orderForm: {
					orderMark: ''
				},

				ordermarkDialogVisible: false,
				startCount: 1,
				CustomerId: null,
				OrderBy: '',
				IsAsc: '',
				exportUrl: config.EXPORT_URL,
				goUrls: config.GO_URL,
				orderStateList: [],
				orderStateType: null,
				imgUrl: config.IMG_BASE,
				orderId: 0,
				result: null,
				companyList: [], //物流公司

				deliverGoodsData: [], //发货详情
				deliverGoodsDialog: false, //发货弹窗控制
				deliverGoodsDialogTitle: '发货', //发货弹窗标题
				deliverGoodsForm: { //发货弹窗表单
					expressNo: '', //物流单号
					logisticsCompany: '' //物流公司
				},
				multipleSelection: [], //发货商品选中数据
				orderTimes:'',//查询时间段
				payTimes:'',//查询时间段
				sendTimes:'',//查询时间段
				finishTimes:'',//查询时间段
				pickerOptions: {        //时间区间提示
					shortcuts: [{
						text: '最近一周',
						onClick (picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick (picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick (picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				totalOrder:0,
				totalMoney:0,
				editAddressForm:{
					receiveName:'',
					receivePhone:'',
					receiveProvince:'',
					receiveCity:'',
					receiveArea:'',
					receiveDetailAddress:'',
					addressArr:''
				},
				editAddressDialog:false,
				addressEditRow:null,//修改收货地址选择的行
				dataList: [],
				props: {
					label: 'name',
					value: 'name',
					children: 'children'
				},
                supplierId:null,    //供应商id
                brandId:null,    //品牌id
                productList:[], //供应商数据
                brandList: [], //品牌数据
                showBrandList: [], //展示的品牌数据
			}
		},
		beforeMount() {
			var date = new Date();
			var year = date.getFullYear();
			var month = date.getMonth() + 1;
			var strDate = date.getDate();
			if (month >= 1 && month <= 9) {
				month = "0" + month;
			}
			if (strDate >= 0 && strDate <= 9) {
				strDate = "0" + strDate;
			}
			var time = year+'-'+month+'-'+strDate
			var endTime = time+' 23:59:59'
			var dates = this.getPreMonth(time);
			var startTime = dates+' 00:00:00';
			var list = [];
			list.push(startTime);
			list.push(endTime);
			this.orderTimes = list;
            this.dataList = util.getAddressList();
            this.getSearchData();
			this.getList();
			this.getorderStatistics();
			this.getCompanyList();
		},
		computed: {

		},
		methods: {
			/* 获取上一个月
			 *
			 * @date 格式为yyyy-mm-dd的日期
			 */
			getPreMonth(date) {
				var arr = date.split('-');
				var year = arr[0]; //获取当前日期的年份
				var month = arr[1]; //获取当前日期的月份
				var day = arr[2]; //获取当前日期的日
				var year2 = year;
				var month2 = parseInt(month) - 1;
				if (month2 == 0) {
					year2 = parseInt(year2) - 1;
					month2 = 12;
				}
				var day2 = day;
				var days2 = new Date(year2, month2, 0);
				days2 = days2.getDate();
				if (day2 > days2) {
					day2 = days2;
				}
				if (month2 < 10) {
					month2 = '0' + month2;
				}
				var t2 = year2 + '-' + month2 + '-' + day2;
				return t2;
			},
			async getorderStatistics(){
				if(this.orderTimes){
					this.orderStartTime = this.orderTimes[0];
					this.orderEndTime = this.orderTimes[1];
				}else{
					this.orderStartTime = '';
					this.orderEndTime = '';
				}
				if(this.payTimes){
					this.payStartTime = this.payTimes[0];
					this.payEndTime = this.payTimes[1];
				}else{
					this.payStartTime = '';
					this.payEndTime = '';
				}
				if(this.sendTimes){
					this.expressStartTime = this.sendTimes[0];
					this.expressEndTime = this.sendTimes[1];
				}else{
					this.expressStartTime = '';
					this.expressEndTime = '';
				}
				if(this.finishTimes){
					this.successStartTime = this.finishTimes[0];
					this.successEndTime = this.finishTimes[1];
				}else{
					this.successStartTime = '';
					this.successEndTime = '';
				}
				let data = {
					pageNo: this.currentPage,
					pageSize: this.pageSize,
					deliverBigTime: this.expressStartTime,
					deliverEndTime: this.expressEndTime,
					goodsName: this.nameKey,
					orderBigTime: this.orderStartTime,
					orderEndTime: this.orderEndTime,
					orderNO: this.orderNoKey,
					orderStatus: this.orderStateType,
					orderType: this.orderType == 0 ? null : this.orderType, //订单类型1：普通订单，2:活动订单
					overBigTime: this.successStartTime,
					overEndTime: this.successEndTime,
					payBigTime: this.payStartTime,
					payEndTime: this.payEndTime,
					paymentChannel: this.payType == 0 ? null : this.payType, //支付类型1：微信，2：支付宝
					phoneNumber: this.phoneKey,
					trackingNo: this.expressNoKey,
					supplierId: this.supplierId,
					brandId: this.brandId,
				}
				try{
					// let data= {
					// 	orderBigTime:this.orderStartTime,
					// 	orderEndTime:this.orderEndTime
					// }
					let res = await orderStatistics(data);
					if(res.success){
						this.initTab(res);
						this.result = res.data;
					}
				}catch(e){
					//TODO handle the exception
				}
			},
            //获取筛选数据
            async getSearchData(){
                //获取供应商
                let result = await supplierGetList({
						pageSize: 10000,
						pageNo: 0
					})
                this.productList = result.data.records;

                //获取品牌商
                let result2 = await findBrandPage({
                    pageSize: 10000,
                    pageNo: 0
                })
                this.brandList = result2.data.records;
                this.showBrandList = JSON.parse(JSON.stringify(this.brandList));
            },
			// 获取商品列表
			async getList() {
				try {
					this.loading = true;
					if(this.orderTimes){
						this.orderStartTime = this.orderTimes[0];
						this.orderEndTime = this.orderTimes[1];
					}else{
						this.orderStartTime = '';
						this.orderEndTime = '';
					}
					if(this.payTimes){
						this.payStartTime = this.payTimes[0];
						this.payEndTime = this.payTimes[1];
					}else{
						this.payStartTime = '';
						this.payEndTime = '';
					}
					if(this.sendTimes){
						this.expressStartTime = this.sendTimes[0];
						this.expressEndTime = this.sendTimes[1];
					}else{
						this.expressStartTime = '';
						this.expressEndTime = '';
					}
					if(this.finishTimes){
						this.successStartTime = this.finishTimes[0];
						this.successEndTime = this.finishTimes[1];
					}else{
						this.successStartTime = '';
						this.successEndTime = '';
					}
					let data = {
						pageNo: this.currentPage,
						pageSize: this.pageSize,
						deliverBigTime: this.expressStartTime,
						deliverEndTime: this.expressEndTime,
						goodsName: this.nameKey,
						orderBigTime: this.orderStartTime,
						orderEndTime: this.orderEndTime,
						orderNO: this.orderNoKey,
						orderStatus: this.orderStateType,
						orderType: this.orderType == 0 ? null : this.orderType, //订单类型1：普通订单，2:活动订单
						overBigTime: this.successStartTime,
						overEndTime: this.successEndTime,
						payBigTime: this.payStartTime,
						payEndTime: this.payEndTime,
						paymentChannel: this.payType == 0 ? null : this.payType, //支付类型1：微信，2：支付宝
						phoneNumber: this.phoneKey,
						trackingNo: this.expressNoKey,
						supplierId: this.supplierId,
						brandId: this.brandId,
					}
					let result = await orderIndex(data);
					if (result.success) {
						this.totalOrder = result.data.countOrder;
						this.totalMoney = result.data.totalAmount;
						result.data.list.records.map(item => {
							item.childOrders.map(childs => {
								//	1 待付款 2 待发货 3 待收货 4 待评价 5 已完成 6 已关闭 7 售后中
								switch (childs.orderStatus) {
									case 1:
										childs.orderStatusValue = '等待买家付款';
										break;
									case 2:
										if(item.orderType==10&&item.groupStatus==1){
											childs.orderStatusValue = '待成团';
										}else{
											childs.orderStatusValue = '等待商家发货';
										}
										break;
									case 3:
										childs.orderStatusValue = '商家已发货';
										break;
									case 4:
										childs.orderStatusValue = '待评价';
										break;
									case 5:
										childs.orderStatusValue = '已完成';
										break;
									case 6:
										childs.orderStatusValue = '交易关闭';
										break;
									case 7:
										childs.orderStatusValue = '售后中';
										break;
									case 8:
										childs.orderStatusValue = '买家付款中';
										break;
									default:
										childs.orderStatusValue = '未知状态';
										break;
								}
								return childs;
							})
							return item;
						})
						this.groupData = result.data.list.records;
						this.Total = result.data.list.total;
						console.log("------",this.groupData)
					}else{
						this.$message({
							showClose: true,
							type: 'error',
							message: result.alertMsg
						});
					}
				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false
				}

			},
			initTab(result) {

				var list = [];
				let obj = {
					title: '全部订单',
					count: result.data.orderTotalVO.countOrder || 0,
					isCheck: this.orderStateType == null,
					state: null
				};
				let obj1 = {
					title: '待付款',
					count: result.data.orderTotalVO.notPayOrder || 0,
					isCheck: this.orderStateType == 1,
					state: 1
				};
				let obj2 = {
					title: '待发货',
					count: result.data.orderTotalVO.notSendOrder || 0,
					isCheck: this.orderStateType == 2,
					state: 2
				};
				let obj3 = {
					title: '待收货',
					count: result.data.orderTotalVO.notReceOrder || 0,
					isCheck: this.orderStateType == 3,
					state: 3
				};
				let obj7 = {
					title: '待评价',
					count: result.data.orderTotalVO.notAppOrder || 0,
					isCheck: this.orderStateType == 4,
					state: 4
				};
				let obj4 = {
					title: '已完成',
					count: result.data.orderTotalVO.overOrder || 0,
					isCheck: this.orderStateType == 5,
					state: 5
				};
				let obj5 = {
					title: '已关闭',
					count: result.data.orderTotalVO.closeOrder || 0,
					isCheck: this.orderStateType == 6,
					state: 6
				};
				let obj6 = {
					title: '售后中',
					count: result.data.orderTotalVO.refundOrder || 0,
					isCheck: this.orderStateType == 7,
					state: 7
				};
				list.push(obj);
				list.push(obj1);
				list.push(obj2);
				list.push(obj3);
				list.push(obj7);
				list.push(obj4);
				list.push(obj5);
				list.push(obj6);

				this.orderStateList = list;
			},

			goodsFilter() {
				this.currentPage = 1;
				this.getorderStatistics();
				this.getList()
			},

			// 订单备注
			orderMark(row) {
				console.log("-------", row)
				this.orderId = row.id;
				this.ordermarkDialogVisible = true;
			},
			// 确认订单备注
			sureOrdermark(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.loading = true;
						try {
							let data = {
								mainOrderId: this.orderId,
								remark: this.orderForm.orderMark
							}
							let result = await orderRemark(data)
							if (result.success) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.ordermarkDialogVisible = false;
								this.getList()
							}
						} catch (error) {
							console.log(error)
						} finally {
							this.loading = false;
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});

			},
			remarkClose() {
				this.orderForm.orderMark = "";
			},
			addressDialogShow(row){
				this.addressEditRow = row;
				this.editAddressForm = {
					receiveName:'',
					receivePhone:'',
					receiveProvince:'',
					receiveCity:'',
					receiveArea:'',
					receiveDetailAddress:'',
					addressArr:''
				}
				this.editAddressDialog = true;
			},
			addressClose(){
				this.editAddressDialog = false;
			},
			// 查看物流
			async lookLogest() {
				this.logistdialogVisible = true;
				try {
					// let result = await orderlookExpress({})
					// this.expressInfo = result.Result

				} catch (e) {
					console.log(e)
				}

			},
			// 查看订单详情
			lookOrderDetails(record) {
                let params = {
                    Id: record.id ? record.id : 0
                }
                this.publicToDetails(params,'/order/orderDetailpage',this);
			},

			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			//取消
			closeDeliver() {
				this.SendDialogVisible = false
			},
			handleClick(index) {
				this.orderStateList.map((item, indexs) => {
					if (index == indexs) {
						item.isCheck = true;
						this.orderStateType = item.state;
					} else {
						item.isCheck = false;
					}
					return item;
				});
				this.currentPage = 1;
				this.getList();
			},
			//获取快递公司数据
			async getCompanyList() {
				let result = await companyList();
				this.companyList = result.data;
			},
			//发货表格选中
			changeFun(val) {
				this.multipleSelection = val;
				console.log(this.multipleSelection);
			},
			//发货
			async deliverGoods(row, type) {
				//获取当前订单包裹详情
				const res = await deliverDetial({
					childOrderId: row.childOrders[0].childOrderId
				});
				if (!res.success) {
					this.$message({
						showClose: true,
						type: 'warning',
						message: res.alertMsg
					});
					return;
				}
				this.deliverGoodsForm.expressNo = '';
				this.deliverGoodsForm.logisticsCompany = '';
				if (type == 1) {
					this.deliverGoodsDialogTitle = '发货';
				} else {
					this.deliverGoodsDialogTitle = '修改物流';
				}
				this.deliverGoodsData = res.data;
				this.deliverGoodsDialog = true;
			},
			//确认发货
			sureDeliverGoods() {
				this.$refs['deliverGoodsForm'].validate(async (valid) => {
					if (valid) {
						this.loading = true;
						try {
							let data = {
								expressNo: this.deliverGoodsForm.expressNo,
								logisticsCompany: this.deliverGoodsForm.logisticsCompany,
							}
							if (this.multipleSelection.length) {
								let orderDetailIds = [];
								this.multipleSelection.map(item => {
									orderDetailIds.push(item.orderDetailId);
								})
								data.orderDetailIds = orderDetailIds;
							} else {
								this.$message({
									type: 'error',
									message: '至少选中一个商品！'
								})
								return;
							}
							console.log(data);
							let result = await orderDeliver(data)
							if (result.success) {
								this.$message({
									type: 'success',
									message: '操作成功!'
								});
								this.deliverGoodsDialog = false;

								this.getList();
							} else {
								this.$message({
									type: 'error',
									message: result.alertMsg
								});
							}
						} catch (error) {
							console.log(error)
						} finally {
							this.loading = false;
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			handleAddressChange() {
				this.editAddressForm.receiveProvince = this.editAddressForm.addressArr[0]
				this.editAddressForm.receiveCity = this.editAddressForm.addressArr[1]
				this.editAddressForm.receiveArea = this.editAddressForm.addressArr[2]
				this.dataList.map(item => {
					if (item.name == this.editAddressForm.receiveProvince) {
						this.editAddressForm.receiveProvince = item.name
						item.children.map(items => {
							if (items.name == this.editAddressForm.receiveCity) {
								this.editAddressForm.receiveCity = items.name
								items.children.map(lastitems => {
									if (lastitems.name == this.editAddressForm.receiveArea) {
										this.editAddressForm.receiveArea = lastitems.name
									}
								})
							}
						})
					}
				})
			},
			sureEditAddress(){
				this.$refs['editAddressForm'].validate(async (valid) => {
					if (valid) {
						this.updateAddress();
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			async updateAddress(){
				try{
					let data = {
						orderId:this.addressEditRow.id,
						receiveName:this.editAddressForm.receiveName,
						receivePhone:this.editAddressForm.receivePhone,
						receiveProvince:this.editAddressForm.receiveProvince,
						receiveCity:this.editAddressForm.receiveCity,
						receiveArea:this.editAddressForm.receiveArea,
						receiveDetailAddress:this.editAddressForm.receiveDetailAddress,
					};
					let res = await updateAddress(data);
					if(res.success){
						this.$message({
							type: 'success',
							message: '收货地址修改成功!'
						});
						this.editAddressDialog = false;
					}else{
						this.$message({
							type: 'error',
							message: res.alertMsg
						});
					}
				}catch(e){
					//TODO handle the exception
				}
			},
			//订单导出
			async orderExport(){
				try{
					this.exportLoading = true;
					let data = {
						isDownload: true,    //下载文件标记
						pageNo: this.currentPage,
						pageSize: this.pageSize,
						deliverBigTime: this.expressStartTime,
						deliverEndTime: this.expressEndTime,
						goodsName: this.nameKey,
						orderBigTime: this.orderStartTime,
						orderEndTime: this.orderEndTime,
						orderNO: this.orderNoKey,
						orderStatus: this.orderStateType,
						orderType: this.orderType == 0 ? null : this.orderType, //订单类型1：普通订单，2:活动订单
						overBigTime: this.successStartTime,
						overEndTime: this.successEndTime,
						payBigTime: this.payStartTime,
						payEndTime: this.payEndTime,
						paymentChannel: this.payType == 0 ? null : this.payType, //支付类型1：微信，2：支付宝
						phoneNumber: this.phoneKey,
						trackingNo: this.expressNoKey,
						supplierId: this.supplierId,
						brandId: this.brandId,
					};
                    await orderExport(data);
                    this.$message({
                        type: 'success',
                        message: '导出成功，请稍后至下载中心查看!'
                    });
				}catch(e){
					this.exportLoading = false;
					//TODO handle the exception
				}finally{
					this.exportLoading = false;
				}
			},
            //供应商切换
            changeSupplierId(){
                let _this = this;
                let brandList = JSON.parse(JSON.stringify(_this.brandList));
                let op = true;
                if (_this.supplierId) {
                    let temp = [];
                    brandList.map(item =>{
                        if (item.supplierId == _this.supplierId) {
                            temp.push(item);
                            if (item.id == _this.brandId) {
                                op = false;
                            }
                        }

                    })
                    _this.showBrandList = temp;
                }else{
                    _this.showBrandList = brandList;
                }
                if (op) {
                    _this.brandId = '';
                }
            },
            //品牌切换
            changeBrandId(){
                this.showBrandList.map(item =>{
                    if (item.id == this.brandId) {
                        this.supplierId = item.supplierId;
                        this.changeSupplierId();
                    }
                })
            }
		}
	}
</script>

<style lang="less">
	.ordermange {
		width: 100%;
		background: #fff;
		padding-top: 30px;
		// padding: 30px;

		.nocanDo {
			.el-dialog__body {
				padding: 10px 20px 20px 20px !important;
			}
		}

		input[type="number"]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		.number__input {
			border: 1px solid #ccc;
		}

		.classBottoms {
			margin-bottom: 10px;
		}

		.label {
			text-align: right;
			display: inline-block;
			width: 100px;
		}

		.pointers {
			cursor: pointer;
		}

		.sendClass {
			color: #333;
			font-size: 14px;
			padding: 0 5px;
			line-height: 25px;
		}

		.prograss-box {
			position: fixed;
			right: 0;
			bottom: 60px;
			z-index: 100;
			background: #dcdfe6;
		}

		.el-alert {
			border-radius: 0;
		}

		/deep/.unexpand .el-table__expand-column .cell {
			display: none;
		}

		/deep/.unexpand .el-table__expanded-cell {
			display: none;
		}

		/deep/.el-table__expanded-cell[class*="cell"] {
			padding: 0 0 0 50px;
		}

		/deep/.el-table__expanded-cell {
			padding-left: 50px;
		}

		.bath-checkorders {
			.el-dialog__header {
				padding: 0px 20px 15px !important;
			}
		}

		input[type="number"]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		.number__input {
			border: 1px solid #ccc;
		}

		.editMoneyNotice {
			background: rgba(253, 246, 236, 1);
			color: rgba(253, 162, 60, 1);
			padding: 10px;
			word-wrap: break-word;
			width: 95%;
			margin-top: 10px;
		}
	}

	.el-alert {
		.el-alert__content {
			width: 100%;
		}

		.el-alert__title {
			word-wrap: break-word;
		}
	}

	.table-container {
		.default-row {
			background: #f0f9eb;
		}
	}
</style>
